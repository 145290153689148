import React, { useEffect, useState } from 'react'
import mainApi from '../../../assets/api/MainApi';
import { MAIN_API_URL } from '../../../assets/utils/constants';
import { DeleteCartIcon, MinusIcon, PlusIcon } from '../../../assets/utils/icons';
import { getDifference } from '../../../assets/utils/utils';
import Button from '../../Button/Button';
import SubmitActionPopup from '../../SubmitActionPopup/SubmitActionPopup';
import './Cart.css'
import AddPopup from './AddPopup/AddPopup';

const limit = 5

const Cart = ({ order, isCurrent, init_items }) => {

    const [items, setItems] = useState([])
    const [itemsToChange, setItemsToChange] = useState(isCurrent ? [] : init_items)

    const [isMoreAvialible, setMoreAvailible] = useState(false)


    useEffect(() => {
        if (!isCurrent) return
        mainApi.getItems({ order_id: order._id, limit })
            .then((res) => {
                console.log(res)
                setItems(res.data)
                setMoreAvailible(res.is_more)
                setItemsToChange(res.data)
            })
            .catch((err) => {
                console.log(err)
            })
    }, [isCurrent])


    const [isLoadPreloaderVisible, setLoadPreloaderVisible] = useState(false)
    function handleLoadMore() {
        if (!isCurrent) return
        if (isLoadPreloaderVisible) return
        setLoadPreloaderVisible(true)
        const last_id = items[items.length - 1]._id
        mainApi.getItems({ order_id: order._id, limit: limit, last_id })
            .then((res) => {
                console.log(res.data)

                setItems(prevValue => prevValue.concat(res.data))
                setMoreAvailible(res.is_more)
                setItemsToChange(prevValue => prevValue.concat(res.data))
            })
            .catch((err) => {
                console.log(err)
            })
            .finally(() => {
                setLoadPreloaderVisible(false)
            })
    }

    const [isEditPreloaderVisible, setEditPreloaderVisible] = useState(false)
    const [isEditSubmitOpen, setEditSubmitOpen] = useState(false)
    function handleEdit() {
        setEditSubmitOpen(true)
    }

    function handleEditSubmit() {
        if (!isCurrent) return
        if (isEditPreloaderVisible) return
        setEditPreloaderVisible(true)
        const itemsToChangeCopy = itemsToChange
        const data = itemsToChange.map((item) => {
            return {
                item_id: item.item_id,
                amount: item.amount.toString(),
            }
        })
        mainApi.editItemsInOrder({ order_id: order._id, data })
            .then((res) => {
                console.log(res)
                setItems(itemsToChangeCopy)
            })
            .catch((err) => {
                console.log(err)
            })
            .finally(() => {
                setEditSubmitOpen(false)
                setEditPreloaderVisible(false)
            })
    }

    function handleClear() {
        setItemsToChange(items)
    }

    function handlePlus(item) {
        setItemsToChange(prevValue => prevValue.map((product) => {
            if (product.item_id !== item.item_id) return product
            else return {
                ...product,
                amount: Number(product.amount) + 1,
            }
        }))
    }

    function handleMinus(item) {
        setItemsToChange(prevValue => prevValue.map((product) => {
            if (product.item_id !== item.item_id) return product
            else return {
                ...product,
                amount: Number(product.amount) - 1,
            }
        }))
    }

    function handleDelete(item) {
        setItemsToChange(prevValue => prevValue.filter((product) => product.item_id !== item.item_id))
    }

    const [cartAllProductsCount, setCartAllProductsCount] = useState({
        amount: 0,
        totalPrice: 0
    })
    useEffect(() => {
        let counter = {
            amount: 0,
            totalPrice: 0
        }
        if (itemsToChange) {
            itemsToChange.forEach(item => {
                counter.amount = Number(counter.amount) + Number(item.amount)
                counter.totalPrice = counter.totalPrice + (Number(item.price) * Number(item.amount))


            });
        }
        setCartAllProductsCount(counter)

    }, [itemsToChange]);

    const isDifferent = isMoreAvialible ?
        false
        :
        items.length === itemsToChange.length ?
            getDifference(items, itemsToChange).length > 0
            :
            true



    const availibleEditFromStatus = isCurrent ? ['new', 'in_work', 'ready'].indexOf(order.status) >= 0 : false

    const [isAddOpen, setAddOpen] = useState(false)
    function handleAdd() {
        setAddOpen(true)
    }
    return (
        <div className="cart">
            <SubmitActionPopup
                isSubmitPreloader={isEditPreloaderVisible}
                hendleSubmit={handleEditSubmit}
                hendleClose={() => { setEditSubmitOpen(false) }}
                isOpen={isEditSubmitOpen}
                text={`Вы уверены что хотите изменить состав заказа?`} submitText={'Изменить состав'} cencelText={'Отменить'} />
            <AddPopup
                itemsToChange={itemsToChange}
                setItemsToChange={setItemsToChange}
                isOpen={isAddOpen}
                hendleClose={() => { setAddOpen(false) }}
                order={order}
                submitText={'Добавить товар'}
                cencelText={'Отменить'}
                findText={'Найти'}
            />
            {isCurrent ? <p className='cart__title'>Состав заказа - {isMoreAvialible ? Number(order.total_price).toLocaleString('us') : Number(cartAllProductsCount.totalPrice).toLocaleString('us')}₽</p> : null}
            <div className='cart__items'>
                {itemsToChange && itemsToChange.length > 0 ?
                    itemsToChange.map((item, i) => (
                        <div className='cart__item'>
                            <img className='cart__item-img' src={`${MAIN_API_URL}/get-file/${item.item.photos[0]}`} alt="" />
                            <p className='cart__item-name'>{item.item.name}</p>
                            <div className='cart__item-controllers'>
                                <div className='cart__item-counters-and-price'>
                                    {isMoreAvialible || !availibleEditFromStatus ? null :
                                        <div className='cart__item-counters'>
                                            <div className={`cart__item-counter-btn ${Number(item.amount) === 1 ? 'cart__item-counter-btn_inactive' : ''}`} onClick={() => {
                                                handleMinus(item)
                                            }}>
                                                <MinusIcon mainClassName={'cart__item-counter-btn-icon'} />
                                            </div>
                                            <p className='cart__item-counter-amount'>{item.amount}</p>
                                            <div className={`cart__item-counter-btn`} onClick={() => {
                                                handlePlus(item)
                                            }}>
                                                <PlusIcon mainClassName={'cart__item-counter-btn-icon'} />
                                            </div>
                                        </div>
                                    }
                                    <p className='cart__item-price'>{!isCurrent ? `(${item.amount}) ` : ''}{Number(item.price).toLocaleString('us')}₽</p>
                                </div>
                                {isMoreAvialible || !availibleEditFromStatus ? null :
                                    <div className='cart__item-delete' onClick={() => {
                                        handleDelete(item)
                                    }}>
                                        <DeleteCartIcon mainClassName={'cart__item-delete-icon'} />
                                    </div>}
                            </div>
                        </div>
                    ))

                    : null}

            </div>
            {isCurrent ?
                <>
                    {isMoreAvialible ?
                        <Button
                            isPreloaderVisible={isLoadPreloaderVisible}
                            className={'cart__load-more'}
                            isBgActiveColor={true}
                            text={'Загрузить еще'}
                            onClick={handleLoadMore} />
                        :
                        <div className='cart__handlers'>
                            {isDifferent ?
                                <div className='cart__edit-handlers'>
                                    <Button

                                        className={'cart__edit-handler'}
                                        isBgActiveColor={true}
                                        text={'Сохранить изменения'}
                                        onClick={handleEdit} />
                                    <Button
                                        // isPreloaderVisible={isLoadPreloaderVisible}
                                        className={'cart__edit-handler'}
                                        isBgTextColor={true}
                                        text={'Сбросить'}
                                        onClick={handleClear} />
                                </div>
                                : <div></div>}
                            <Button
                                className={'cart__edit-handler'}
                                isBgActiveColor={true}
                                text={'Добавить товар'}
                                onClick={handleAdd} />
                        </div>}
                </>
                : null}

        </div>
    )
};

export default Cart