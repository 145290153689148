import './AddPopup.css';
import React, { useEffect, useState } from "react";
import MiniPreloader from '../../../MiniPreloader/MiniPreloader';
import mainApi from '../../../../assets/api/MainApi';
import { MAIN_API_URL } from '../../../../assets/utils/constants';
import { getPrice, getRandomId } from '../../../../assets/utils/utils';







function AddPopup({ order, isOpen, hendleClose, submitText, cencelText, findText, setItemsToChange, itemsToChange }) {
  const [product, setProduct] = useState(undefined)
  const [textValue, setTextValue] = useState('')
  const [isSubmitPreloader, setSubmitPreloader] = useState(false)

  const [err, setErr] = useState('')

  function handleClosePopup() {
    setTextValue('')
    setProduct(undefined)
    hendleClose()
  }
  function handleChange(e) {
    const value = e.target.value
    setTextValue(value)
  }

  function hendleFind() {
    if (isSubmitPreloader) return
    setSubmitPreloader(true)
    console.log(textValue)
    const category = textValue.split('/')[4]
    const sub_category = textValue.split('/')[5]
    const item_id = textValue.split('/')[6]
    console.table({ category, sub_category, item_id })
    if (!category || !sub_category || !item_id) {
      setSubmitPreloader(false)
      setErr('Не правильная ссылка')
      setTimeout(() => {
        setErr('')
      }, 3000);
      return

    }

    mainApi.getExactItem({
      category_translit_name: category,
      sub_category_translit_name: sub_category,
      _id: item_id,
    })
      .then((res) => {
        console.log(res)
        setProduct(res)
      })
      .catch((err) => {
        setErr('Товар не найден')
        setTimeout(() => {
          setErr('')
        }, 3000);
        console.log(err)
      })
      .finally(() => {
        setSubmitPreloader(false)
      })
    // mainApi.createNote({ order_id: order._id, text: textValue })
    //   .then((res) => {
    //     console.log(res)
    //     setNotes(prevValue => [res, ...prevValue])
    //   })
    //   .catch((err) => {
    //     console.log(err)
    //   })
    //   .finally(() => {
    //     setSubmitPreloader(false)
    //     handleClosePopup()
    //   })
    // hendleSubmit(text)
  }


  function handleAdd() {
    if (itemsToChange.some((item) => item.item_id === product._id)) {
      setErr('Такой товар уже есть в корзине')
      setTimeout(() => {
        setErr('')
      }, 3000);
      return
    }


    setItemsToChange(prevValue => [...prevValue, {
      amount: '1',
      item_id: product._id,
      order_id: order._id,
      price: getPrice(product).toString(),
      item: product,
      _id: getRandomId(),
    }])
    handleClosePopup()
  }
  return (
    <div className={`add-popup ${isOpen ? 'add-popup_active' : ''}`}>
      <div className={`add-popup__container ${isOpen ? 'add-popup__container_active' : ''}`}>
        <div className="add-popup__close" onClick={handleClosePopup}>
          <svg className="add-popup__close-icon" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M2 18L18 2M2 2L18 18" stroke="black" strokeWidth="3.2" strokeLinecap="round" />
          </svg>
        </div>
        <div className={`add-popup__input-container`}>
          <input placeholder='Вставьте ссылку на товар' className="add-popup__input" name="text" type="text" value={textValue} onChange={handleChange} maxLength="250"></input>

        </div>
        {product ?
          <div className='add-popup__product'>
            <img className='cart__item-img' src={`${MAIN_API_URL}/get-file/${product.photos[0]}`} alt="" />
            <p className='cart__item-name'>{product.name} / {getPrice(product).toLocaleString('us')}₽</p>
          </div>
          : null}
        <div className="add-popup__btns">
          {err ? <p className='add-popup__err'>{err}</p> : null}
          <div className={`add-popup__btn add-popup__btn_agree ${product ? true : textValue && textValue.length > 0 && textValue.split('/').length === 7 ? '' : 'add-popup__btn_disabled'}`} onClick={() => {
            if (product) {
              handleAdd()
            } else {
              hendleFind()
            }
          }}>
            {isSubmitPreloader ?
              <MiniPreloader isLinkColor={true} />
              :
              <p className="add-popup__btn-text add-popup__btn-text_agree">{product ? submitText : findText}</p>
            }
          </div>
          <div className="add-popup__btn add-popup__btn_disagree" onClick={handleClosePopup}>
            <p className="add-popup__btn-text add-popup__btn-text_disagree">{cencelText}</p>
          </div>
        </div>

      </div>
      <div className={`add-popup__background ${isOpen ? 'add-popup__background_active' : ''}`} onClick={handleClosePopup}>

      </div>
    </div>
  );
}

export default AddPopup;
