import React from 'react'
import { parsePhone } from '../../../assets/utils/utils';
import './ClientData.css'

const ClientData = ({ order }) => {
    return (
        <div className='client-data'>
            <p className='client-data__title'>Данные клиента</p>
            <div className='client-data__infos'>
                <div className='client-data__info'>
                    <p className='client-data__info-title'>Имя:</p>
                    <p className='client-data__info-value'>{order.first_name}</p>
                </div>
                <div className='client-data__info'>
                    <p className='client-data__info-title'>Фамилия:</p>
                    <p className='client-data__info-value'>{order.last_name}</p>
                </div>
                <div className='client-data__info'>
                    <p className='client-data__info-title'>Телефон:</p>
                    <a className='client-data__info-value client-data__info-value_link' rel="noreferrer" href={`tel:+${order.phone}`}>{parsePhone(order.phone)}</a>
                </div>
                <div className='client-data__info'>
                    <p className='client-data__info-title'>Email:</p>
                    <a className='client-data__info-value client-data__info-value_link' rel="noreferrer" href={`mailto:+${order.email}`}>{order.email}</a>

                </div>
            </div>
        </div>
    )
};

export default ClientData