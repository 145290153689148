import React, { useState } from 'react'
import mainApi from '../../../assets/api/MainApi';
import { ArrowIcon } from '../../../assets/utils/icons';
import { AVAILABLE_STATUS_MAP, parseStatus } from '../../../assets/utils/utils';
import SubmitActionPopup from '../../SubmitActionPopup/SubmitActionPopup';

import './StatusDrop.css'

const StatusDrop = ({ order, setOrder }) => {
    const [isDropOpen, setDropOpen] = useState(false)
    const [isSubmitOpen, setSubmitOpen] = useState(false)
    const [selectedStatus, setSelectedStatus] = useState(undefined)

    function handleSelectStatus(status) {
        setSelectedStatus(status)
        setSubmitOpen(true)
    }
    function handleCloseSubmit() {
        setDropOpen(false)
        setSubmitOpen(false)
        setTimeout(() => {
            setSelectedStatus(undefined)
        }, 300);
    }
    const [isSubmitPreloader, setSubmitPreloader] = useState(false)
    function hendleSubmit() {
        if (isSubmitPreloader) return
        setSubmitPreloader(true)
        mainApi.editStatus({ _id: order._id, status: selectedStatus })
            .then((res) => {
                console.log(res)
                setOrder(prevValue => ({
                    ...prevValue,
                    status: selectedStatus,
                }))
                handleCloseSubmit()
            })
            .catch((err) => {
                console.log(err)
            })
            .finally(() => {
                setSubmitPreloader(false)
            })
    }
    return (
        <>
            <SubmitActionPopup isSubmitPreloader={isSubmitPreloader} hendleSubmit={hendleSubmit} hendleClose={handleCloseSubmit} isOpen={isSubmitOpen} text={selectedStatus ? `Вы уверены что хотите изменить статус заказа ${`№${('00000000' + order.ai_id).slice(-8)}`} на "${parseStatus(selectedStatus)}"` : ''} submitText={'Изменить статус'} cencelText={'Отменить'} />
            <div className='status-drop'>
                <div className={`status-drop__selected status-drop__selected_status_${order.status}`} onClick={() => {
                    setDropOpen(!isDropOpen)
                }}>
                    <div className='status-drop__arrow'>
                        <ArrowIcon mainClassName={`status-drop__arrow-icon ${isDropOpen ? 'status-drop__arrow-icon_active' : ''}`} />
                    </div>
                    {parseStatus(order.status)}
                </div>
                {isDropOpen ?
                    AVAILABLE_STATUS_MAP[order.status].length > 0 ?
                        <div className='status-drop__drop'>
                            {
                                AVAILABLE_STATUS_MAP[order.status].map((item, i) => (
                                    <p className='status-drop__drop-item' onClick={() => {
                                        handleSelectStatus(item)
                                    }}>{parseStatus(item)}</p>
                                ))
                            }
                        </div>
                        :
                        null
                    : null}
            </div>
        </>

    )
};

export default StatusDrop