import moment from 'moment-timezone';
import React, { useState } from 'react'
import { Link } from 'react-router-dom';
import { parsePhone, parseStatus } from '../../assets/utils/utils';


import MiniPreloader from '../MiniPreloader/MiniPreloader';


import './Table.css'


// headingList, list
const Table = ({ listInnerRef, headingList, list, title, nothingText, isInitTablePreloaderVisible }) => {

    const [linkCopied, setLinkCopied] = useState('')


    function copyToClipboard(text, type, i) {

        var textArea = document.createElement("textarea");
        textArea.style.position = 'fixed';
        textArea.style.top = 0;
        textArea.style.left = 0;
        textArea.style.width = '2em';
        textArea.style.height = '2em';
        textArea.style.padding = 0;
        textArea.style.border = 'none';
        textArea.style.outline = 'none';
        textArea.style.boxShadow = 'none';
        textArea.style.background = 'transparent';
        textArea.value = text;
        document.body.appendChild(textArea);
        textArea.focus();
        textArea.select();

        try {
            var successful = document.execCommand('copy');
            var msg = successful ? 'successful' : 'unsuccessful';

            if (type === 'link') {
                setLinkCopied(i)
                setTimeout(() => {
                    setLinkCopied('')
                }, 1500);
            }
            console.log('Copying text command was ' + msg);
        } catch (err) {
            console.log('Oops, unable to copy');
        }

        document.body.removeChild(textArea);
    }

    return (
        <div className='table'>
            <>{isInitTablePreloaderVisible ?
                <div className='table__preloader'>
                    <MiniPreloader />
                </div>
                :
                <>
                    {list !== undefined ?
                        <>
                            {title && <p className='table__title'>{title}</p>}
                            {list.length > 0 ?
                                <div className='table__table-container'>
                                    <table className='table__table'>
                                        <thead className='table__thead'>
                                            <tr className='table__thead-tr'>
                                                {headingList ? headingList.map((item, i) => (

                                                    <th className={`table__thead-th ${item.key === 'connected_via' ? 'table__thead-th_referrals-amount' : ''} ${item.type === 'ref-link-from-code-withut-copy' ? 'table__thead-th_right' : ''}`} colSpan="1" key={`table__thead-th${i}`}>
                                                        <span>
                                                            {item.name}
                                                        </span>

                                                    </th>


                                                )) : <></>}
                                            </tr>
                                        </thead>

                                        <tbody className='table__tbody' ref={listInnerRef}>
                                            {list ? list.map((item, i) => (
                                                <tr className='table__tbody-tr' key={`table__tbody-tr${i}`}>
                                                    {headingList.map((head_item, i2) => (
                                                        <td className={`table__tbody-td ${head_item.type === 'statusLink' ? ' table__tbody-td_status' : ''}`} key={`table__tbody-tr${i}table__tbody-td${i2}`}>
                                                            {head_item.type === 'text' ? item[head_item.key] ? item[head_item.key] : '-' : ''}
                                                            {head_item.type === 'request_number' ? `№${('00000000' + item[head_item.key]).slice(-8)}` : ''}
                                                            {head_item.type === 'phone' ? <a className='table__phone' rel="noreferrer" href={`tel:+${item[head_item.key]}`} >{parsePhone(item[head_item.key])}</a> : ''}
                                                            {head_item.type === 'full_name' ?
                                                                <div className='table__column'>
                                                                    <p className='table__first-name'>{item[head_item.key].first_name}</p>
                                                                    <p className='table__last-name'>{item[head_item.key].last_name}</p>

                                                                </div> : null}
                                                            {head_item.type === 'email' ? <a className='table__email' rel="noreferrer" href={`mailto:${item[head_item.key]}`} >{item[head_item.key]}</a> : ''}
                                                            {head_item.type === 'price' ? `${Number(item[head_item.key]).toLocaleString('us')}₽` : ''}
                                                            {head_item.type === 'link' ?
                                                                <Link to={item[head_item.key]}>
                                                                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                        <rect width="16" height="16" fill="white" />
                                                                        <path d="M13.9468 3.30657L12.9972 2.35697C12.7558 2.12769 12.4357 2 12.103 2C11.7702 2 11.4502 2.1277 11.2089 2.35697L6.69278 6.87306C6.62301 6.94368 6.57555 7.03321 6.55641 7.13068L6.05122 9.65642C6.01644 9.82291 6.06771 9.99564 6.18759 10.1161C6.28294 10.2106 6.41201 10.2634 6.54626 10.2627H6.64732L9.17306 9.75747C9.27053 9.73833 9.36007 9.69087 9.43068 9.6211L13.9468 5.10501V5.10491C14.1831 4.86738 14.3158 4.54592 14.3158 4.21081C14.3158 3.8757 14.1831 3.55423 13.9468 3.31671L13.9468 3.30657ZM8.8245 8.78769L7.18779 9.11603L7.51614 7.47933L10.5874 4.40807L11.8958 5.71646L8.8245 8.78769ZM13.2294 4.37768L12.608 5.00411L11.2996 3.69572L11.926 3.07436C11.9718 3.02552 12.0359 2.99782 12.1028 2.99782C12.1698 2.99782 12.2338 3.02552 12.2796 3.07436L13.2293 4.02407C13.2782 4.06994 13.3059 4.1339 13.3059 4.20092C13.3059 4.26784 13.2782 4.33191 13.2294 4.37768Z" fill="black" />
                                                                        <path d="M11.3453 14.3039H3.76801C3.29908 14.3039 2.84939 14.1176 2.51788 13.786C2.18627 13.4545 2 13.0048 2 12.5359V4.95854C2 4.48962 2.18626 4.03993 2.51788 3.70831C2.84939 3.3767 3.29908 3.19043 3.76801 3.19043H7.0517C7.23214 3.19043 7.39885 3.28673 7.48912 3.44308C7.5794 3.59932 7.5794 3.79193 7.48912 3.94816C7.39885 4.1045 7.23214 4.20081 7.0517 4.20081H3.76801C3.56705 4.20081 3.37434 4.28062 3.23226 4.42269C3.09019 4.56477 3.01027 4.75758 3.01027 4.95854V12.5359C3.01027 12.7368 3.09019 12.9295 3.23226 13.0716C3.37434 13.2137 3.56705 13.2936 3.76801 13.2936H11.3453C11.5463 13.2936 11.7391 13.2137 11.8812 13.0716C12.0233 12.9295 12.1031 12.7368 12.1031 12.5359V9.25218C12.1031 9.07173 12.1994 8.90503 12.3557 8.81476C12.512 8.72448 12.7046 8.72448 12.8608 8.81476C13.0171 8.90503 13.1135 9.07174 13.1135 9.25218V12.5359C13.1135 13.0048 12.9272 13.4545 12.5956 13.786C12.264 14.1176 11.8143 14.3039 11.3453 14.3039Z" fill="black" />
                                                                    </svg>

                                                                </Link>
                                                                : null}
                                                            {head_item.type === 'status' ? item[head_item.key] ? <p className={`table-status_status_${item[head_item.key]}`}>{parseStatus(item[head_item.key])}</p> : '-' : ''}



                                                        </td>
                                                    ))}
                                                </tr>))
                                                : <></>}

                                        </tbody>
                                    </table >
                                </div>
                                :
                                <p className='table__nothing-found'>{nothingText}</p>
                            }
                        </>
                        : <></>}
                </>}
            </>



        </div>
    )
};

export default Table