import moment from 'moment-timezone';
import React, { useEffect, useState } from 'react'
import mainApi from '../../../assets/api/MainApi';
import { DeleteIcon, EditIcon } from '../../../assets/utils/icons';
import Button from '../../Button/Button'
import SubmitActionPopup from '../../SubmitActionPopup/SubmitActionPopup';
import CreatePopup from './CreatePopup/CreatePopup';
import './Notes.css'

const limit = 3

const Notes = ({ order, isCurrent, init_items }) => {
    const [notes, setNotes] = useState(isCurrent ? [] : init_items)
    const [isMoreAvialible, setMoreAvailible] = useState(false)
    const [createOpen, setCreateOpen] = useState(false)
    const [editOpen, setEditOpen] = useState(false)


    function handleCreateOpen() {
        setCreateOpen(true)
    }

    useEffect(() => {
        if (!isCurrent) return
        mainApi.getNotes({ order_id: order._id, limit: limit })
            .then((res) => {
                console.log(res.data)
                setNotes(res.data)
                setMoreAvailible(res.is_more)
            })
            .catch((err) => {
                console.log(err)
            })
    }, [isCurrent])

    const [itemToEdit, setItemToEdit] = useState(undefined)
    function hendleEdit(item) {
        setItemToEdit(item)
        setEditOpen(true)

    }

    const [isDeleteOpen, setDeleteOpen] = useState(false)
    const [isDeletePreloader, setDeletePreloader] = useState(false)

    const [itemToDelete, setItemToDelete] = useState(undefined)

    function hendleDeliteOpen(item) {
        setItemToDelete(item)
        setDeleteOpen(true)
    }
    function hendleDelete() {
        if (isDeletePreloader) return
        setDeletePreloader(true)
        mainApi.deleteNote({ _id: itemToDelete._id })
            .then((res) => {
                console.log(res)
                setNotes(prevValue => prevValue.filter((item) => item._id !== itemToDelete._id))
            })
            .catch((err) => {
                console.log(err)
            })
            .finally(() => {
                setDeletePreloader(false)
                setItemToDelete(undefined)
                setDeleteOpen(false)
            })
    }

    const [isLoadPreloaderVisible, setLoadPreloaderVisible] = useState(false)
    function handleLoadMore() {
        if (isLoadPreloaderVisible) return
        setLoadPreloaderVisible(true)
        const last_id = notes[notes.length - 1]._id
        mainApi.getNotes({ order_id: order._id, limit: limit, last_id })
            .then((res) => {
                console.log(res.data)
                setNotes(prevValue => prevValue.concat(res.data))
                setMoreAvailible(res.is_more)
            })
            .catch((err) => {
                console.log(err)
            })
            .finally(() => {
                setLoadPreloaderVisible(false)
            })
    }
    return (
        <>
            {isCurrent || (init_items && init_items.length > 0) ?
                <div className="notes">
                    <SubmitActionPopup
                        isSubmitPreloader={isDeletePreloader}
                        hendleSubmit={hendleDelete}
                        hendleClose={() => {
                            setDeleteOpen(false)
                            setItemToDelete(undefined)
                        }}
                        isOpen={isDeleteOpen}
                        text={`Вы уверены что хотите эту заметку?`}
                        submitText={'Удалить'}
                        cencelText={'Отменить'} />
                    <CreatePopup isEdit={true} itemToEdit={itemToEdit} order={order} setNotes={setNotes} isOpen={editOpen} hendleClose={() => { setEditOpen(false) }} submitText={'Изменить'} cencelText={'Отменить'} />

                    <CreatePopup order={order} setNotes={setNotes} isOpen={createOpen} hendleClose={() => { setCreateOpen(false) }} submitText={'Создать'} cencelText={'Отменить'} />
                    <p className="notes__title">Заметки по заказу</p>
                    {isCurrent ?
                        <Button
                            className={'notes__create'}
                            isBgActiveColor={true}
                            text={'Создать заметку'}
                            onClick={handleCreateOpen} />
                        : null}

                    <div className='notes__cards'>
                        {notes && notes.length > 0 ?
                            notes.map((item, i) => (
                                <div className='notes__card'>
                                    <div className='notes__card-heading'>
                                        <p className='notes__card-date'>{moment(item.utc_date).format('DD.MM.YYYY')}</p>
                                        {isCurrent ?
                                            <div className='notes__card-controllers'>
                                                <div className='notes__card-controller' onClick={() => {
                                                    hendleEdit(item)
                                                }}>
                                                    <EditIcon />
                                                </div>
                                                <div className='notes__card-controller' onClick={() => {
                                                    hendleDeliteOpen(item)
                                                }}>
                                                    <DeleteIcon />
                                                </div>
                                            </div>
                                            : <div></div>}
                                    </div>
                                    <p className='notes__card-text'>{item.text}</p>
                                </div>
                            ))

                            : null}

                    </div>
                    {isMoreAvialible ?
                        <Button
                            isPreloaderVisible={isLoadPreloaderVisible}
                            className={'notes__load-more'}
                            isBgActiveColor={true}
                            text={'Загрузить еще'}
                            onClick={handleLoadMore} />
                        : null}
                </div>
                : null}

        </>

    )
};

export default Notes