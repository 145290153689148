export function parsePhone(phone) {
    let formattedInputValue = '';
    if (['7', '8', '9'].indexOf(phone[0]) > -1) {

        if (phone[0] === '9') phone = '7' + phone;

        let firstSimbols = (phone[0] === '8') ? '8' : '+7';
        formattedInputValue = firstSimbols + ' '

        if (phone.length > 1) {
            formattedInputValue += '(' + phone.substring(1, 4)
        }
        if (phone.length >= 5) {
            formattedInputValue += ') ' + phone.substring(4, 7)
        }
        if (phone.length >= 8) {
            formattedInputValue += '-' + phone.substring(7, 9)
        }
        if (phone.length >= 10) {
            formattedInputValue += '-' + phone.substring(9, 11)
        }

    } else {
        formattedInputValue = '+' + phone.substring(0, 16)

    }


    return formattedInputValue
}

export function parseStatus(status) {
    const status_map = {
        new: 'Новый',
        in_work: 'В работе',
        ready: 'Готов к выдаче',
        done: 'Завершен',
        cancelled: 'Отменен',
    }

    const status_text = status_map[status] ? status_map[status] : 'Нет статуса'
    return status_text
}

export const AVAILABLE_STATUS_MAP = {
    new: [
        'in_work',
        'ready',
        'done',
        'cancelled',
    ],
    in_work: [
        'ready',
        'done',
        'cancelled',
    ],
    ready: [
        'done',
        'cancelled',
    ],
    done: [],
    cancelled: [],
}

export function getDifference(array1, array2) {
    return array1.filter(object1 => {
        return !array2.some(object2 => {
            return Number(object1.amount) === Number(object2.amount) && object1.item_id === object2.item_id;

        });
    });
}

export const getPrice = (item) => {
    let id = "63777e74c505252a8fc59c0b"
    let value = item.firstc_data.price[id]
    return Number(value)
}

export function getRandomId() {
    const ObjectId = (m = Math, d = Date, h = 16, s = s => m.floor(s).toString(h)) =>
        s(d.now() / 1000) + ' '.repeat(h).replace(/./g, () => s(m.random() * h))
    return ObjectId()

}


export function getCorrectWordForm(count) {
    let lastDigit = count % 10;
    if (count >= 11 && count <= 19) {
        return 'товаров';
    }
    switch (lastDigit) {
        case 1:
            return 'товар';
        case 2:
        case 3:
        case 4:
            return 'товара';
        default:
            return 'товаров';
    }
}
