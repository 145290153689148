import React, { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom';
import mainApi from '../../assets/api/MainApi';
import { BackIcon } from '../../assets/utils/icons';

import MiniPreloader from '../MiniPreloader/MiniPreloader';
import Cart from './Cart/Cart';
import ClientData from './ClientData/ClientData';
import DeliveryMethod from './DeliveryMethod/DeliveryMethod';
import Notes from './Notes/Notes';
import './Order.css'
import PrevOrders from './PrevOrders/PrevOrders';
import StatusDrop from './StatusDrop/StatusDrop';


const Order = () => {
    const { id } = useParams()
    const [order, setOrder] = useState(undefined)
    const [isPreloaderVisible, setPreloaderVisible] = useState(true)
    useEffect(() => {
        if (!id) return
        setPreloaderVisible(true)
        mainApi.getOrder({ _id: id })
            .then((res) => {
                console.log(res)
                setOrder(res)
            })
            .catch((err) => {
                console.log(err)
            })
            .finally(() => {
                setPreloaderVisible(false)
            })
    }, [id])

    return (
        <div className="order">
            <Link className='order__back' to={'/'}>
                <BackIcon />
                Назад к заказам
            </Link>
            {isPreloaderVisible ?
                <div className='order__preloader'>
                    <MiniPreloader />
                </div>
                :
                <>
                    {order ?
                        <div className='order__content'>
                            <div className='order__info'>
                                <div className='order__head'>
                                    <div className='order__number'>
                                        <p className='order__number-title'>Номер заказа</p>
                                        <p className='order__number-value'>{`№${('00000000' + order.ai_id).slice(-8)}`}</p>
                                    </div>
                                </div>
                                <Notes order={order} isCurrent={true} />
                                <Cart order={order} isCurrent={true} />
                                <DeliveryMethod order={order} isCurrent={true} />
                                <PrevOrders order={order} />
                            </div>
                            <div className='order__main-info'>
                                <StatusDrop order={order} setOrder={setOrder} />
                                <ClientData order={order} />
                            </div>

                        </div >

                        : <p>Заказ не найден</p>}
                </>


            }


        </div >
    )
};

export default Order