import moment from 'moment-timezone';
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import mainApi from '../../../assets/api/MainApi';
import { getCorrectWordForm, parsePhone } from '../../../assets/utils/utils';
import MiniPreloader from '../../MiniPreloader/MiniPreloader';
import Cart from '../Cart/Cart';
import DeliveryMethod from '../DeliveryMethod/DeliveryMethod';
import Notes from '../Notes/Notes';
import './PrevOrders.css'

const limit = 25
const PrevOrders = ({ order }) => {
    const [orders, setOrders] = useState([])
    const [isPreloaderVisible, setPreloaderVisible] = useState(true)

    useEffect(() => {
        setPreloaderVisible(true)
        mainApi.getPrevOrders({
            user_id: order.user_id,
            exclude_id: order._id,
            limit: limit
        })
            .then((res) => {
                console.log(res.data)
                setOrders(res.data)
            })
            .catch((err) => {
                console.log(err)
            })
            .finally(() => {
                setPreloaderVisible(false)
            })
    }, [])
    return (
        <>
            {isPreloaderVisible ?
                <div className="prev-orders__preloader">
                    <MiniPreloader />
                </div>
                :
                <div className="prev-orders">
                    <p className='prev-orders__title'>Прошлые заказы {parsePhone(order.phone)}</p>
                    {orders && orders.length > 0 ?
                        orders.map((item, i) => (
                            <div className='prev-orders__card'>
                                <div className='prev-orders__card-head'>
                                    <div className='prev-orders__card-date-number'>
                                        <Link className='prev-orders__card-number' to={`/order/${item._id}`}>{`№${('00000000' + item.ai_id).slice(-8)}`}</Link>
                                        <p className='prev-orders__card-date'>{moment(item.utc_date).format('DD.MM.YYYY')}</p>
                                    </div>
                                    <p>Всего - {item.total_items} {getCorrectWordForm(item.total_items)} / {item.different_items} различных</p>
                                </div>
                                <Cart init_items={item.order_items} />
                                <DeliveryMethod init_items={item} />
                                <Notes init_items={item.order_notes} />
                            </div>
                        ))
                        :
                        <p>Нет прошлых заказов</p>}
                </div>}
        </>

    )
};

export default PrevOrders