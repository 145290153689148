
import { useEffect, useState } from "react";
import mainApi from "../../assets/api/MainApi";
import MiniPreloader from "../MiniPreloader/MiniPreloader";
import Table from "../Table/Table";
import "./Main.css";

const limit = 25
function Main() {


    const [orders, setOrders] = useState(undefined)

    useEffect(() => {
        mainApi.getOrders({ limit: limit })
            .then((res) => {
                console.log(res.data)
                setOrders(res.data)
            })
            .catch((err) => {
                console.log(err)
            })
    }, [])

    const [isLoadPreloaderVisible, setLoadPreloaderVisible] = useState(false)
    function loadMore() {
        if (isLoadPreloaderVisible) return
        setLoadPreloaderVisible(true)
        const last_id = orders[orders.length - 1]._id
        mainApi.getOrders({ limit: limit, last_id: last_id })
            .then((res) => {
                setOrders(prevValue => prevValue.concat(res.data))
            })
            .catch((err) => {
                console.log(err)
            })
            .finally(() => {
                setLoadPreloaderVisible(false)
            })
    }

    return (
        <div className="main">
            <p className="main__title">Заказы</p>
            <div>
                <Table
                    headingList={[
                        {
                            name: 'Номер заказ',
                            key: 'ai_id',
                            type: 'request_number',
                        },
                        {
                            name: 'Статус',
                            key: 'status',
                            type: 'status',
                        },
                        {
                            name: 'ФИО',
                            key: 'full_name',
                            type: 'full_name',
                        },
                        {
                            name: 'Телефон',
                            key: 'phone',
                            type: 'phone',
                        },
                        {
                            name: 'Почта',
                            key: 'email',
                            type: 'email',
                        },
                        {
                            name: 'Количество товаров',
                            key: 'different_items',
                            type: 'text',
                        },

                        {
                            name: 'Сумма заказа',
                            key: 'total_price',
                            type: 'price',
                        },

                        {
                            name: ' ',
                            key: 'link',
                            type: 'link',
                        },
                        // {
                        //     name: translation.yourTeam.tabelLinkLabel,
                        //     key: 'connected_via',
                        //     type: 'ref-link-from-code-withut-copy',
                        // },

                    ]}

                    list={orders ? orders.map((item) => {
                        return {
                            ...item,
                            full_name: {
                                first_name: item.first_name,
                                last_name: item.last_name,
                            },
                            link: `/order/${item._id}`
                        }
                    }) : undefined}
                />
            </div>
            {orders && orders.length > 0 && orders[orders.length - 1].ai_id !== '1' ?
                <button className="main__load-more" type="button" onClick={loadMore}>
                    {isLoadPreloaderVisible ? <MiniPreloader isLinkColor={true} /> : 'Загрузить еще'}
                </button>
                : null}

        </div>
    );
}

export default Main;
