export const MAIN_URL = 'https://app.divanchik72.com/admin'
export const MAIN_API_URL = 'https://app.divanchik72.com'


export const ID_REGEX = /^[a-zA-Z0-9]{12}$/;

export const ID_VALID_ERROR =
  "Код пользователя должен состоять из 12 символов и содержать только цифры и латинские буквы";

export const EMAIL_REQUIRED_ERROR = "Введите email пользователя";

export const PASS_REQUIRED_ERROR = "Введите пароль пользователя";


export const FIELD_REQUIRED_ERROR = "Заполните это поле";

export const URL_VALID_ERROR = "Ссылка не валидна";

export const PHOTO_REQUIRED_ERROR = "Добавьте до 10 фотографий";

export const PHOTO_TOO_BIG_ERROR = "Файл слишком большой";

export const PHOTO_WRONG_TYPE_ERROR =
  "Не подходящий формат файла, можно загружать фотографии в формате jpeg, webp и jpg";

export const PHOTO_MAX_ERROR =
  "К одному товару можно добавить максимум 10 фотографий";

export const ROOM_LIST = [
  { text: "Гостиная" },
  { text: "Спальня" },
  { text: "Сад и дача" },
  { text: "Кухня" },
  { text: "Детская" },
  { text: "Ванная" },
  { text: "Офис" },
  { text: "Прихожая" },
  { text: "Балкон" },
  { text: "Гардеробная" },
  { text: "Комната не указана" },
  { text: "Универсальный" },
];

export const NOT_FOUND_REASON = "Не нашёл этот товар";

export const WRONG_NAME_REASON = "Неправильное название/производитель";

export const REASONS_LIST = [
  { text: NOT_FOUND_REASON },
  { text: WRONG_NAME_REASON },
];

export const EXEPTIONS_ARRAY = [
  'бренд',
  'производитель',
  'гарантия',
  'размер',
  'артикул',
  'базовая единица',
  'код товара',
  'код',
  'гарантия',
  'гарантия, месяцы',
  'гарантия мес',
  'срок гарантии, лет',
  'срок гарантии',
  'срок гарантии лет',
  'снят с производства',
  'вариантов исполнения',
  'размеры',
  'размеры, мм',
  'размеры, см',
  'доставим бесплатно',
  'изготовитель',
  'категория товара',
  'категория',
  'срок доставки',
  'доставка',
  'комната',
  'для комнаты',
  'коллекция',
  'из коллекции',
  'предмет мебели',
  'срок изготовления',
  'подъём',
  'сборка',
  'доставка',
  'дата доставки',
  'наличие',
  'изготовление',
  'изготовление и доставка'
]