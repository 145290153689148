import './CreatePopup.css';
import React, { useEffect, useState } from "react";
import MiniPreloader from '../../../MiniPreloader/MiniPreloader';
import mainApi from '../../../../assets/api/MainApi';







function CreatePopup({ order, isOpen, hendleClose, submitText, cencelText, setNotes, itemToEdit, isEdit }) {
  const [textValue, setTextValue] = useState('')
  const [isSubmitPreloader, setSubmitPreloader] = useState(false)

  function handleClosePopup() {
    setTextValue('')
    hendleClose()
  }
  function handleChange(e) {
    const value = e.target.value
    setTextValue(value)
  }

  function hendleCreate() {
    if (isSubmitPreloader) return
    setSubmitPreloader(true)
    console.log(textValue)
    if (isEdit) {
      mainApi.editNote({ _id: itemToEdit._id, text: textValue })
        .then((res) => {
          console.log(res)
          setNotes(prevValue => prevValue.map((item) => {
            if (item._id !== itemToEdit._id) return item
            else {
              return res
            }
          }))
        })
        .catch((err) => {
          console.log(err)
        })
        .finally(() => {
          setSubmitPreloader(false)
          handleClosePopup()
        })
    } else {
      mainApi.createNote({ order_id: order._id, text: textValue })
        .then((res) => {
          console.log(res)
          setNotes(prevValue => [res, ...prevValue])
        })
        .catch((err) => {
          console.log(err)
        })
        .finally(() => {
          setSubmitPreloader(false)
          handleClosePopup()
        })
    }

    // hendleSubmit(text)
  }

  useEffect(() => {
    if (!itemToEdit) return
    setTextValue(itemToEdit.text)
  }, [itemToEdit])
  return (
    <div className={`create-popup ${isOpen ? 'create-popup_active' : ''}`}>
      <div className={`create-popup__container ${isOpen ? 'create-popup__container_active' : ''}`}>
        <div className="create-popup__close" onClick={handleClosePopup}>
          <svg className="create-popup__close-icon" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M2 18L18 2M2 2L18 18" stroke="black" strokeWidth="3.2" strokeLinecap="round" />
          </svg>
        </div>
        <div className={`create-popup__input-container`}>
          <input placeholder='Напишите заметку' className="create-popup__input" name="text" type="text" value={textValue} onChange={handleChange} maxLength="250"></input>

        </div>
        <div className="create-popup__btns">
          <div className={`create-popup__btn create-popup__btn_agree ${textValue && textValue.length > 0 ? '' : 'create-popup__btn_disabled'}`} onClick={hendleCreate}>
            {isSubmitPreloader ?
              <MiniPreloader isLinkColor={true} />
              :
              <p className="create-popup__btn-text create-popup__btn-text_agree">{submitText}</p>
            }
          </div>
          <div className="create-popup__btn create-popup__btn_disagree" onClick={handleClosePopup}>
            <p className="create-popup__btn-text create-popup__btn-text_disagree">{cencelText}</p>
          </div>
        </div>

      </div>
      <div className={`create-popup__background ${isOpen ? 'create-popup__background_active' : ''}`} onClick={handleClosePopup}>

      </div>
    </div>
  );
}

export default CreatePopup;
