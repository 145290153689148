import { useState } from "react";
import { Link } from "react-router-dom";
import mainApi from "../../assets/api/MainApi";
import {
  USER_ICON,
  CLOSE_ICON,
  WRAPPED_MENU_ICON,
} from "../../assets/utils/icons";
import "./Header.css";

function Header({ user, setLoggedIn, setUser }) {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  function logout(){
    mainApi.logout()
    .then((res)=>{
      setLoggedIn(false) 
      setUser({})
      console.log(res)
    })
    .catch((err)=>{
      console.log(err)
    })
  }

  return (
    <header className="header">
      <div className="header__content-box">
        <Link to="/" className="header__logo">
          div-admin
        </Link>

        <div
          className={`header__nav-box ${isOpen ? "header__nav-box_menu-open" : ""
            }`}
        >
          <div className="header__nav-wrapper">
            <div className="header__nav-wrapper-box">
              <div className="header__icons-box">
                <Link
                  to="/"
                  className="header__logo"
                  onClick={isOpen ? toggleMenu : undefined}
                >
                  div-admin
                </Link>
                <button
                  className="header__close-button"
                  type="button"
                  aria-label="Close navigation"
                  onClick={toggleMenu}
                >
                  {CLOSE_ICON}
                </button>
              </div>

              <ul className="header__info-list">

                <li className="header__item">
                  <div className="header__user-icon-box">{USER_ICON}</div>
                  <p className="header__info-text">{user.email}</p>
                </li>
                <li className="header__item">
                  {/* <div className="header__user-icon-box">{USER_ICON}</div> */}
                  <p className="header__info-text">{user.role}</p>
                </li>
                <button type="button" className="header__logout" onClick={logout}>
                  Выйти
                </button>
              </ul>

            </div>
          </div>

          <button
            className="header__open-button"
            type="button"
            aria-label="Open navigation"
            onClick={toggleMenu}
          >
            {WRAPPED_MENU_ICON}
          </button>
          <div className="header__background" />
        </div>
      </div>
    </header>
  );
}

export default Header;
