import { useState, useEffect } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import mainApi from "../../assets/api/MainApi";
import Header from "../Header/Header";
import Main from "../Main/Main";
import Auth from "../Auth/Auth";
import "./App.css";
import Order from "../Order/Order";

function App() {
  const [isLoggedIn, setLoggedIn] = useState(false);
  const [user, setUser] = useState({});

  const [preloader, setPreloader] = useState(true)

  useEffect(() => {
    setPreloader(true)
    mainApi
      .getMe()
      .then((res) => {
        console.log(res);
        setLoggedIn(true);
        setUser(res);
      })
      .catch((err) => {
        console.log(err);
        setLoggedIn(false);
      })
      .finally(() => {
        setPreloader(false)
      })


  }, []);



  return (
    <div className="App">
      {
        preloader ? null :
          <>
            {isLoggedIn ? <Header {...{ user, setLoggedIn, setUser }} /> : null}

            {!isLoggedIn ? (
              <Routes>
                <Route
                  path="/auth"
                  element={<Auth {...{ setLoggedIn, setUser }} />}
                />
                <Route path="*" element={<Navigate to="/auth" replace />} />
              </Routes>
            ) : (
              <div className="app__content">
                <Routes>
                  <Route path="/" element={<Main />} />
                  <Route path="/order/:id" element={<Order />} />
                  <Route path="*" element={<Navigate to="/" replace />} />
                </Routes>

              </div>
            )}
          </>
      }


    </div>
  );
}

export default App;
