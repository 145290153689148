import React, { useState } from 'react'
import mainApi from '../../../assets/api/MainApi';

import Button from '../../Button/Button';



import './DeliveryMethod.css';





function DeliveryMethod({
    order,
    isCurrent,
    init_items,
}) {

    const [delivery, setDelivery] = useState({
        is_delivery: init_items ? init_items.receive_data ? init_items.receive_data.is_delivery : false : order.receive_data ? order.receive_data.is_delivery : false,
        is_private_house: init_items ? init_items.receive_data && init_items.receive_data.delivery ? init_items.receive_data.delivery.is_private_house : false : order.receive_data && order.receive_data.delivery ? order.receive_data.delivery.is_private_house : false,
        main: init_items ? init_items.receive_data && init_items.receive_data.delivery && init_items.receive_data.delivery.main ? init_items.receive_data.delivery.main : '' : order.receive_data && order.receive_data.delivery && order.receive_data.delivery.main ? order.receive_data.delivery.main : '',
        apartment: init_items ? init_items.receive_data && init_items.receive_data.delivery && init_items.receive_data.delivery.apartment ? init_items.receive_data.delivery.apartment : '' : order.receive_data && order.receive_data.delivery && order.receive_data.delivery.apartment ? order.receive_data.delivery.apartment : '',
        entrance: init_items ? init_items.receive_data && init_items.receive_data.delivery && init_items.receive_data.delivery.entrance ? init_items.receive_data.delivery.entrance : '' : order.receive_data && order.receive_data.delivery && order.receive_data.delivery.entrance ? order.receive_data.delivery.entrance : '',
        floor: init_items ? init_items.receive_data && init_items.receive_data.delivery && init_items.receive_data.delivery.floor ? init_items.receive_data.delivery.floor : '' : order.receive_data && order.receive_data.delivery && order.receive_data.delivery.floor ? order.receive_data.delivery.floor : '',
    })

    function handleChange(e) {
        let value = e.target.value
        let name = e.target.name
        if (isCurrent) setDelivery(prevValue => ({
            ...prevValue,
            [name]: value,
        }))
    }


    const [isSavePreloaderVisible, setSavePreloaderVisible] = useState(false)
    function handleSave() {
        if (isSavePreloaderVisible) return
        setSavePreloaderVisible(true)
        let receive_data = {}
        if (delivery.is_delivery) {
            if (delivery.is_private_house) {
                receive_data = {
                    is_delivery: delivery.is_delivery,
                    delivery: {
                        is_private_house: delivery.is_private_house,
                        main: delivery.main,
                    },
                }
            } else {
                receive_data = {
                    is_delivery: delivery.is_delivery,
                    delivery: {
                        is_private_house: delivery.is_private_house,
                        main: delivery.main,
                        apartment: delivery.apartment ? delivery.apartment : null,
                        entrance: delivery.entrance ? delivery.entrance : null,
                        floor: delivery.floor ? delivery.floor : null,
                    },
                }
            }
        } else {
            receive_data = {
                is_delivery: delivery.is_delivery,
                delivery: null,
            }
        }
        mainApi.editDelivery({ _id: order._id, receive_data: receive_data })
            .then((res) => {
                console.log(res)
            })
            .catch((err) => {
                console.log(err)
            })
            .finally(() => {
                setSavePreloaderVisible(false)
            })
    }
    return (
        <>
            {isCurrent || (init_items && init_items.receive_data) ?

                <section className="delivery">


                    <p className='delivery__title'>Способ получения</p>
                    <div className='delivery__form'>
                        <div className='delivery__selectors'>
                            <button className={`delivery__selector ${!delivery.is_delivery ? 'delivery__selector_selected' : ''}`} type='button' onClick={() => {
                                if (isCurrent) setDelivery(prevValue => ({
                                    ...prevValue,
                                    is_delivery: false,
                                }))
                            }}>
                                Самовывоз
                            </button>
                            <button className={`delivery__selector ${delivery.is_delivery ? 'delivery__selector_selected' : ''}`} type='button' onClick={() => {
                                if (isCurrent) setDelivery(prevValue => ({
                                    ...prevValue,
                                    is_delivery: true,
                                }))
                            }}>
                                Доставка
                            </button>
                        </div>
                        {delivery.is_delivery ?
                            <div className='delivery__delivery-form'>
                                <div className='delivery__privat-house-selector'>
                                    <div className={`delivery__privat-house-chekbox ${delivery.is_private_house ? 'delivery__privat-house-chekbox_cheked' : ''}`} onClick={() => {
                                        if (isCurrent) setDelivery(prevValue => ({
                                            ...prevValue,
                                            is_private_house: !prevValue.is_private_house,
                                        }))
                                    }}>
                                        <svg className={`delivery__privat-house-chekbox-tick`} width="11" height="9" viewBox="0 0 11 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M4.39821 7.01968L9.74179 1.2468L9.83502 1.32639L4.03128 7.59641L1.27606 4.61983L1.3693 4.54024L3.66435 7.01968L4.03128 7.4161L4.39821 7.01968Z" stroke="var(--contrast-color)" />
                                        </svg>
                                    </div>
                                    <p className='delivery__privat-house-title'>Частный дом</p>
                                </div>
                                <div className='delivery__inputs'>
                                    <div className={`delivery__input-container`}>
                                        <input placeholder='Город, улица и дом*' className="delivery__input" name="main" type="text" value={delivery.main} onChange={handleChange} maxLength="250"></input>

                                    </div>
                                    {!delivery.is_private_house ?
                                        <div className='delivery__inputs-row'>
                                            <div className={`delivery__input-container`}>
                                                <input placeholder='Квартира' className="delivery__input" name="apartment" type="text" value={delivery.apartment} onChange={handleChange} maxLength="250"></input>

                                            </div>
                                            <div className={`delivery__input-container`}>
                                                <input placeholder='Подъезд' className="delivery__input" name="entrance" type="text" value={delivery.entrance} onChange={handleChange} maxLength="250"></input>

                                            </div>
                                            <div className={`delivery__input-container`}>
                                                <input placeholder='Этаж' className="delivery__input" name="floor" type="text" value={delivery.floor} onChange={handleChange} maxLength="250"></input>

                                            </div>
                                        </div>
                                        : null}

                                </div>

                            </div>
                            : null}
                        {isCurrent ?
                            <Button
                                isInactive={delivery.is_delivery ? !delivery.main.length > 0 : false}
                                isPreloaderVisible={isSavePreloaderVisible}
                                className={'delivery__save'}
                                isBgActiveColor={true}
                                text={'Сохранить изменения'}
                                onClick={handleSave} />
                            : null}
                    </div>
                </section>
                : null}
        </>

    );
}

export default DeliveryMethod;
