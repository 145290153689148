import { MAIN_API_URL, MAIN_URL } from "../utils/constants";

class MainApi {
  constructor({ baseUrl }) {
    this._BASE_URL = baseUrl;
  }

  _checkResponse(res) {
    if (res.ok) {
      return res.json();
    } else {
      const statusCode = res.status;
      return res.json().then((res) => {
        return Promise.reject({
          statusCode: statusCode,
          message: res.msg,
          detail: res.detail,
        });
      });
    }
  }

  _checkResponseWithCookies({ res, func, params }) {
    if (res.ok) {
      return res.json();
    } else {
      const statusCode = res.status;
      return res.json().then((res) => {
        if (statusCode === 422 && res.detail === "Signature has expired") {
          console.log("ss");
          return mainApi
            .refreshJWT()
            .then((res) => {
              return func(params);
            })
            .catch((err) => {
              return Promise.reject(err);
            });
        } else {
          return Promise.reject({
            statusCode: statusCode,
            message: res.msg,
            detail: res.detail,
          });
        }
      });
    }
  }

  refreshJWT() {
    return fetch(`${this._BASE_URL}/user/refresh-jwt`, {
      method: "POST",
      credentials: "include",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    }).then(this._checkResponse);
  }

  login({ email, password }) {
    return fetch(`${this._BASE_URL}/user/login`, {
      method: "POST",
      credentials: "include",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        email: email,
        password: password,
      }),
    }).then(this._checkResponse);
  }

  logout() {
    return fetch(`${this._BASE_URL}/user/logout`, {
      method: "DELETE",
      credentials: "include",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    }).then(this._checkResponse);
  }

  getMe() {
    return fetch(`${MAIN_URL}/user/get-me`, {
      method: "GET",
      credentials: "include",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    }).then((res) =>
      mainApi._checkResponseWithCookies({
        res: res,
        func: mainApi.getMe,
        params: {},
      })
    );
  }

  getOrders({ last_id, limit }) {
    let params = {}
    if (last_id) params.last_id = last_id
    if (limit) params.limit = limit

    return fetch(`${MAIN_URL}/orders/get-all?` + new URLSearchParams(params), {
      method: "GET",
      credentials: "include",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    }).then((res) =>
      mainApi._checkResponseWithCookies({
        res: res,
        func: mainApi.getOrders,
        params: { last_id, limit },
      })
    );
  }

  getOrder({ _id }) {
    let params = {}
    if (_id) params._id = _id


    return fetch(`${MAIN_URL}/orders/get-exact?` + new URLSearchParams(params), {
      method: "GET",
      credentials: "include",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    }).then((res) =>
      mainApi._checkResponseWithCookies({
        res: res,
        func: mainApi.getOrder,
        params: { _id },
      })
    );
  }

  editStatus({ _id, status }) {


    return fetch(`${MAIN_URL}/orders/edit-status`, {
      method: "POST",
      credentials: "include",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        _id: _id,
        status: status,
      }),
    }).then((res) =>
      mainApi._checkResponseWithCookies({
        res: res,
        func: mainApi.editStatus,
        params: { _id, status },
      })
    );
  }


  createNote({ order_id, text }) {


    return fetch(`${MAIN_URL}/orders/notes/create`, {
      method: "POST",
      credentials: "include",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        order_id: order_id,
        text: text,
      }),
    }).then((res) =>
      mainApi._checkResponseWithCookies({
        res: res,
        func: mainApi.createNote,
        params: { order_id, text },
      })
    );
  }

  getNotes({ order_id, last_id, limit }) {
    let params = {}
    if (order_id) params.order_id = order_id
    if (last_id) params.last_id = last_id
    if (limit) params.limit = limit



    return fetch(`${MAIN_URL}/orders/notes/get-by-order-id?` + new URLSearchParams(params), {
      method: "GET",
      credentials: "include",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    }).then((res) =>
      mainApi._checkResponseWithCookies({
        res: res,
        func: mainApi.getNotes,
        params: { order_id, last_id, limit },
      })
    );
  }

  editNote({ _id, text }) {
    return fetch(`${MAIN_URL}/orders/notes/patch`, {
      method: "PATCH",
      credentials: "include",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        _id: _id,
        text: text,
      }),
    }).then((res) =>
      mainApi._checkResponseWithCookies({
        res: res,
        func: mainApi.editNote,
        params: { _id, text },
      })
    );
  }

  deleteNote({ _id }) {
    return fetch(`${MAIN_URL}/orders/notes/delete`, {
      method: "DELETE",
      credentials: "include",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        _id: _id,
      }),
    }).then((res) =>
      mainApi._checkResponseWithCookies({
        res: res,
        func: mainApi.deleteNote,
        params: { _id },
      })
    );
  }

  getItems({ order_id, last_id, limit }) {
    let params = {}
    if (order_id) params.order_id = order_id
    if (last_id) params.last_id = last_id
    if (limit) params.limit = limit



    return fetch(`${MAIN_URL}/orders/items/get-by-order-id?` + new URLSearchParams(params), {
      method: "GET",
      credentials: "include",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    }).then((res) =>
      mainApi._checkResponseWithCookies({
        res: res,
        func: mainApi.getItems,
        params: { order_id, last_id, limit },
      })
    );
  }

  editItemsInOrder({ order_id, data }) {
    return fetch(`${MAIN_URL}/orders/items/edit`, {
      method: "POST",
      credentials: "include",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        order_id: order_id,
        data: data,
      }),
    }).then((res) =>
      mainApi._checkResponseWithCookies({
        res: res,
        func: mainApi.editItemsInOrder,
        params: { order_id, data },
      })
    );
  }

  getExactItem({ category_translit_name, sub_category_translit_name, _id }) {
    let params = {}
    if (category_translit_name) params.category_translit_name = category_translit_name
    if (sub_category_translit_name) params.sub_category_translit_name = sub_category_translit_name
    if (_id) params._id = _id



    return fetch(`${MAIN_API_URL}/items/get-exact?` + new URLSearchParams(params), {
      method: "GET",
      credentials: "include",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    }).then(this._checkResponse);
  }

  editDelivery({ _id, receive_data }) {
    return fetch(`${MAIN_URL}/orders/edit-delivery`, {
      method: "POST",
      credentials: "include",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        _id: _id,
        receive_data: receive_data,
      }),
    }).then((res) =>
      mainApi._checkResponseWithCookies({
        res: res,
        func: mainApi.editDelivery,
        params: { _id, receive_data },
      })
    );
  }

  getPrevOrders({ user_id, exclude_id, last_id, limit }) {
    let params = {}
    if (user_id) params.user_id = user_id
    if (exclude_id) params.exclude_id = exclude_id
    if (last_id) params.last_id = last_id
    if (limit) params.limit = limit



    return fetch(`${MAIN_URL}/orders/get-orders-by-user-id?` + new URLSearchParams(params), {
      method: "GET",
      credentials: "include",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    }).then((res) =>
      mainApi._checkResponseWithCookies({
        res: res,
        func: mainApi.getPrevOrders,
        params: { user_id, exclude_id, last_id, limit },
      })
    );
  }

}

const mainApi = new MainApi({
  baseUrl: MAIN_URL,
});

export default mainApi;
