export const USER_ICON = (
  <svg
    className="user-icon"
    width="18"
    height="17"
    viewBox="0 0 18 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      className="user-icon-fill"
      d="M12.0904 9.09169C12.9074 8.44891 13.5038 7.56746 13.7965 6.56998C14.0892 5.57249 14.0637 4.50858 13.7236 3.52625C13.3835 2.54391 12.7457 1.69202 11.8988 1.08907C11.052 0.486122 10.0383 0.162109 8.99878 0.162109C7.95923 0.162109 6.94554 0.486122 6.09872 1.08907C5.2519 1.69202 4.61406 2.54391 4.27395 3.52625C3.93384 4.50858 3.90837 5.57249 4.20108 6.56998C4.49378 7.56746 5.09012 8.44891 5.90711 9.09169C4.50717 9.65256 3.28568 10.5828 2.37285 11.7833C1.46002 12.9838 0.890079 14.4094 0.723777 15.9084C0.711739 16.0178 0.721374 16.1285 0.752131 16.2342C0.782888 16.34 0.834166 16.4386 0.903036 16.5245C1.04213 16.6979 1.24443 16.809 1.46544 16.8334C1.68646 16.8577 1.90808 16.7932 2.08155 16.6541C2.25502 16.515 2.36613 16.3127 2.39044 16.0917C2.57343 14.4627 3.35018 12.9582 4.57229 11.8657C5.7944 10.7732 7.37619 10.1692 9.01544 10.1692C10.6547 10.1692 12.2365 10.7732 13.4586 11.8657C14.6807 12.9582 15.4575 14.4627 15.6404 16.0917C15.6631 16.2965 15.7608 16.4856 15.9147 16.6225C16.0686 16.7595 16.2678 16.8346 16.4738 16.8334H16.5654C16.7839 16.8082 16.9835 16.6978 17.1209 16.5261C17.2583 16.3544 17.3222 16.1353 17.2988 15.9167C17.1317 14.4135 16.5587 12.9842 15.6412 11.7819C14.7236 10.5795 13.4962 9.64959 12.0904 9.09169ZM8.99878 8.50002C8.33951 8.50002 7.69504 8.30453 7.14688 7.93826C6.59871 7.57199 6.17147 7.05139 5.91918 6.4423C5.66689 5.83322 5.60087 5.16299 5.72949 4.51639C5.85811 3.86979 6.17558 3.27584 6.64175 2.80967C7.10793 2.34349 7.70187 2.02602 8.34848 1.89741C8.99508 1.76879 9.6653 1.8348 10.2744 2.08709C10.8835 2.33938 11.4041 2.76663 11.7703 3.31479C12.1366 3.86295 12.3321 4.50742 12.3321 5.16669C12.3321 6.05075 11.9809 6.89859 11.3558 7.52371C10.7307 8.14884 9.88283 8.50002 8.99878 8.50002Z"
      fill="#FFFFFF"
    />
  </svg>
);

export const WRAPPED_MENU_ICON = (
  <svg
    className="header__open-button-icon"
    width="52"
    height="21"
    viewBox="0 0 52 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      className="header__open-button-icon-fill"
      x="9"
      y="0.5"
      width="43"
      height="6"
      rx="3"
      fill="#FFFFFF"
    />
    <rect
      className="header__open-button-icon-fill"
      x="22"
      y="14.5"
      width="30"
      height="6"
      rx="3"
      fill="#FFFFFF"
    />
  </svg>
);

export const CLOSE_ICON = (
  <svg
    className="close-button-icon"
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      className="close-button-icon-stroke"
      d="M11.6836 2.33398L2.33194 11.6856"
      stroke="white"
      strokeWidth="3"
      strokeLinecap="round"
    />
    <path
      className="close-button-icon-stroke"
      d="M2.33203 2.33398L11.6837 11.6856"
      stroke="white"
      strokeWidth="3"
      strokeLinecap="round"
    />
  </svg>
);

export const SELECT_ARROW_ICON = (
  <svg
    className="select-arrow-icon"
    width="18"
    height="11"
    viewBox="0 0 18 11"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      className="select-arrow-icon-fill"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.7294 10.093L17.0983 2.61776C17.5007 2.18262 17.5007 1.51699 17.0983 1.10733C16.6959 0.697744 16.0169 0.697744 15.6144 1.10733L9.0001 7.84011L2.38574 1.10733C1.95826 0.697744 1.30434 0.697744 0.901884 1.10733C0.499502 1.51691 0.499502 2.18252 0.901884 2.61776L8.24559 10.093C8.67308 10.5025 9.327 10.5025 9.72945 10.093H9.7294Z"
      fill="#6F7974"
    />
  </svg>
);

export const CHECKED_CHECKBOX_ICON = (
  <svg
    className="checked-checkbox-icon"
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      className="checked-checkbox-icon-fill"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2 0H16C17.1 0 18 0.9 18 2V16C18 17.1 17.1 18 16 18H2C0.9 18 0 17.1 0 16V2C0 0.9 0.9 0 2 0ZM13.6 4.6L15 6L7 14L3 10L4.4 8.6L7 11.2L13.6 4.6Z"
      fill="#08CBBD"
    />
    <path
      className="checked-checkbox-icon-fill"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2 0H16C17.1 0 18 0.9 18 2V16C18 17.1 17.1 18 16 18H2C0.9 18 0 17.1 0 16V2C0 0.9 0.9 0 2 0ZM13.6 4.6L15 6L7 14L3 10L4.4 8.6L7 11.2L13.6 4.6Z"
      fill="#48617B"
      fillOpacity="0.07"
    />
  </svg>
);

export const ADD_ITEM_ICON = (
  <svg
    className="add-item-icon"
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle
      className="add-item-icon-circle"
      cx="20"
      cy="20"
      r="20"
      transform="rotate(-180 20 20)"
      fill="#00BFB4"
    />
    <path
      className="add-item-icon-stroke"
      d="M13 20H28"
      stroke="white"
      strokeWidth="2"
      strokeLinecap="round"
    />
    <path
      className="add-item-icon-stroke"
      d="M20.5 12.5L20.5 27.5"
      stroke="white"
      strokeWidth="2"
      strokeLinecap="round"
    />
  </svg>
);

export const COPY_ICON = (
  <svg
    className="copy-icon"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      className="copy-icon-fill"
      d="M15 1H4C2.9 1 2 1.9 2 3V16C2 16.55 2.45 17 3 17C3.55 17 4 16.55 4 16V4C4 3.45 4.45 3 5 3H15C15.55 3 16 2.55 16 2C16 1.45 15.55 1 15 1ZM15.59 5.59L20.42 10.42C20.79 10.79 21 11.3 21 11.83V21C21 22.1 20.1 23 19 23H7.99C6.89 23 6 22.1 6 21L6.01 7C6.01 5.9 6.9 5 8 5H14.17C14.7 5 15.21 5.21 15.59 5.59ZM15 12H19.5L14 6.5V11C14 11.55 14.45 12 15 12Z"
      fill="black"
    />
  </svg>
);

export const REMOVE_BUTTON_ICON = (
  <svg
    className="remove-button-icon"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      className="remove-button-icon-fill"
      d="M10 0C4.48 0 0 4.48 0 10C0 15.52 4.48 20 10 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 10 0ZM14 11H6C5.45 11 5 10.55 5 10C5 9.45 5.45 9 6 9H14C14.55 9 15 9.45 15 10C15 10.55 14.55 11 14 11Z"
      fill="#BC0101"
    />
  </svg>
);


export function BackIcon() {
  return (
    <svg
      width={8}
      height={12}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"


    >
      <path d="M7 1L2 6l5 5" stroke="#000" strokeWidth={2} />
    </svg>
  );
}

export function ArrowIcon({ mainClassName }) {
  return (
    <svg
      width={13}
      height={12}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={mainClassName}
      viewBox="0 0 13 12"
    >
      <path d="M9.196 7.5l-3-3-3 3" stroke="#fff" />
    </svg>
  );
}

export function EditIcon({ mainClassName }) {
  return (
    <svg
      width={20}
      height={20}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={mainClassName}
      viewBox="0 0 20 20"
    >
      <g clipPath="url(#prefix__clip0_2373_25698)">
        <path
          d="M2.5 14.375V17.5h3.125l9.217-9.217-3.125-3.125L2.5 14.375zm15.342-9.092l-3.125-3.125-2.109 2.117L15.733 7.4l2.109-2.117z"
          fill="#949494"
        />
      </g>
      <defs>
        <clipPath id="prefix__clip0_2373_25698">
          <path fill="#fff" d="M0 0h20v20H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}

export function DeleteIcon({ mainClassName }) {
  return (
    <svg
      width={20}
      height={20}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={mainClassName}
      viewBox="0 0 20 20"
    >
      <g clipPath="url(#prefix__clip0_2373_25699)">
        <path
          d="M5 17.5h10V5.833H5V17.5zM15.834 3.333h-2.917l-.833-.833H7.917l-.833.833H4.167V5h11.667V3.333z"
          fill="#949494"
        />
      </g>
      <defs>
        <clipPath id="prefix__clip0_2373_25699">
          <path fill="#fff" d="M0 0h20v20H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}

export function MinusIcon({mainClassName}) {
  return (
    <svg
      width={15}
      height={16}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 15 16"
      className={mainClassName}
    >
      <path fill="#121212" d="M0 7.5h15v1H0z" />
    </svg>
  );
}

export function PlusIcon({mainClassName}) {
  return (
    <svg
      width={15}
      height={16}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 15 16"
      className={mainClassName}
    >
      <g clipPath="url(#prefix__clip0_2717_19228)" fill="#121212">
        <path d="M8 .5v15H7V.5z" />
        <path d="M0 7.5h15v1H0z" />
      </g>
      <defs>
        <clipPath id="prefix__clip0_2717_19228">
          <path fill="#fff" transform="translate(0 .5)" d="M0 0h15v15H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}

export function DeleteCartIcon({mainClassName}) {
  return (
    <svg
      width={20}
      height={20}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 20 20"
      className={mainClassName}
    >
      <path
        d="M6.5 4.375V2.5c0-.69.522-1.25 1.167-1.25h4.666c.645 0 1.167.56 1.167 1.25v1.875M1.25 5H3m0 0h14M3 5v12.5c0 .69.522 1.25 1.167 1.25h11.666c.645 0 1.167-.56 1.167-1.25V5m0 0h1.75M10 9.375v6.25m-3.5-3.75v3.75m7-3.75v3.75"
        stroke="#E11C23"
      />
    </svg>
  );
}
