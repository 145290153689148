import { Link } from 'react-router-dom';
import MiniPreloader from '../MiniPreloader/MiniPreloader';
import './Button.css';




function Button({ className, text, onClick, path, isBgActiveColor, isBgSecondaryActiveColor, leftIcon, isBgTextColor, isBgTransparent, children, isInactive, isPreloaderVisible }) {
    return (
        <>
            {path ?
                <Link className={`button ${isBgActiveColor ? 'button_type_active-color' : ''} ${isBgSecondaryActiveColor ? 'button_type_secondary-active-color' : ''} ${isBgTextColor ? 'button_type_text-color' : ''} ${isBgTransparent ? 'button_type_transparent' : ''} ${isInactive ? 'button_type_inactive' : ''} ${isPreloaderVisible ? 'button_type_loading' : ''} ${className ? className : ''}`} to={path} onClick={onClick}>

                    {isPreloaderVisible ?
                        <MiniPreloader />
                        :
                        <>
                            {leftIcon ? leftIcon({ mainClassName: `button__icon ${!text ? 'button__icon_no-text' : ''}`, strokeClassName: 'button__icon-stroke', fillClassName: 'button__icon-fill' }) : null}
                            {text}
                            {children}
                        </>
                    }

                </Link >
                :
                <button className={`button ${isBgActiveColor ? 'button_type_active-color' : ''} ${isBgSecondaryActiveColor ? 'button_type_secondary-active-color' : ''} ${isBgTextColor ? 'button_type_text-color' : ''} ${isBgTransparent ? 'button_type_transparent' : ''} ${isInactive ? 'button_type_inactive' : ''} ${isPreloaderVisible ? 'button_type_loading' : ''} ${className ? className : ''}`} type='button' onClick={onClick}>
                    {isPreloaderVisible ?
                        <MiniPreloader isLinkColor={isBgActiveColor ? true : false} />
                        :
                        <>
                            {leftIcon ? leftIcon({ mainClassName: `button__icon ${!text ? 'button__icon_no-text' : ''}`, strokeClassName: 'button__icon-stroke', fillClassName: 'button__icon-fill' }) : null}
                            {text}
                            {children}
                        </>
                    }
                </button >
            }
        </>

    );
}

export default Button;
