import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import mainApi from "../../assets/api/MainApi";
import {
  ID_REGEX,
  ID_VALID_ERROR,
  ID_REQUIRED_ERROR,
  PASS_REQUIRED_ERROR,
  EMAIL_REQUIRED_ERROR,
} from "../../assets/utils/constants";
import "./Auth.css";

function Auth({ setLoggedIn, setUser }) {
  const navigate = useNavigate();
  const [loginError, setLoginError] = useState("");

  function handleFormSubmit(data) {
    console.log(data)
    mainApi
      .login(data)
      .then((res) => {
        console.log("user:", res);
        setLoggedIn(true);
        setUser(res);
        navigate("/");
      })
      .catch((err) => {
        console.log("userErr:", err);
        setLoggedIn(false);

        switch (err.detail) {
          case "user not found":
            setLoginError("Неверные данные пользователя");
            setTimeout(() => {
              setLoginError("");
            }, 10000);
            break;
          default:
            break;
        }
      });
  }

  return (
    <section className="auth">
      <div className="auth__form-box">
        <h2 className="auth__title">Вход</h2>
        <Formik
          initialValues={{ email: "", password: "" }}
          validationSchema={Yup.object({
            email: Yup.string().email().required(EMAIL_REQUIRED_ERROR),
            password: Yup.string().min(8).required(PASS_REQUIRED_ERROR)
          })}
          onSubmit={(values, { setSubmitting, resetForm }) => {
            setSubmitting(false);
            handleFormSubmit(values);
            resetForm();
          }}
        >
          {({ errors, touched, isValid, dirty }) => (
            <Form className="auth__form">
              <div className="auth__input-box">
                <label className="auth__label" htmlFor="email">
                  Email
                </label>
                <Field
                  className={`auth__input ${touched.email && Boolean(errors.email)
                    ? "auth__input_error"
                    : ""
                    } ${touched.email && !Boolean(errors.email)
                      ? "auth__input_valid"
                      : ""
                    }`}
                  id="email"
                  name="email"
                  type="email"
                  autoComplete="on"
                />
                <ErrorMessage
                  component="span"
                  className="auth__error-message"
                  name="email"
                />


              </div>

              <div className="auth__input-box">
                <label className="auth__label" htmlFor="password">
                  Пароль
                </label>
                <Field
                  className={`auth__input ${touched.password && Boolean(errors.password)
                    ? "auth__input_error"
                    : ""
                    } ${touched.password && !Boolean(errors.password)
                      ? "auth__input_valid"
                      : ""
                    }`}
                  id="password"
                  name="password"
                  type="password"
                  autoComplete="on"
                />
                <ErrorMessage
                  component="span"
                  className="auth__error-message"
                  name="password"
                />

                {Boolean(loginError) ? (
                  <span className="auth__error-message">{loginError}</span>
                ) : null}
              </div>

              <button
                className={`auth__submit-button ${!(isValid && dirty) ? "auth__submit-button_disabled" : ""
                  }`}
                type="submit"
              >
                Войти
              </button>
            </Form>
          )}
        </Formik>
      </div>
    </section>
  );
}

export default Auth;
